import React from 'react';
import { RichText } from 'prismic-reactjs';
import { linkResolver } from '../../utils/linkResolver'
import SplitText from '../../components/splitText';

import './styles.scss';

const TextBlock = ({
	title = null,
	subtitle = null,
	paragraphs = null,
	buttonLink = null,
	buttonText = null,
	className,
	isSplitAppear = null
}) => {

	return (
		<section className={`TextBlock ${className}`}>
			{title &&
				<h3 className={`TextBlock__Title ${isSplitAppear ? 'appear' : ''}`} data-scroll> {title[0].text} </h3>
			}
			{subtitle &&
				<h4 className={`TextBlock__Subtitle ${isSplitAppear ? 'appear' : ''}`} data-scroll> {subtitle[0].text} </h4>
			}
			{paragraphs &&
				<div className={`TextBlock__Paragraphs ${subtitle ? 'bigMarginTop' : 'smallMarginTop'} ${isSplitAppear ? 'appear' : ''}`} data-scroll>
					<SelectSplitAppear isSplitAppear={isSplitAppear}>
						<RichText render={paragraphs} serializeHyperlink={linkResolver} />
					</SelectSplitAppear>
				</div>
			}
			{buttonLink && buttonText &&
				<a href={buttonLink[0].text} target="_blank" className={`TextBlock__Button ${isSplitAppear ? 'appear' : ''}`} data-scroll>
					<span className="TextBlock__ButtonContainer">
						<span className="TextBlock__ButtonLine">
							<span className="TextBlock__ButtonText"> {buttonText[0].text} </span>
							<svg className="TextBlock__ButtonArrow" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.264.408l-.032 1.28 8.032.16-10.24 10.24.928.896L11.16 2.744l.192 8.064 1.28-.032L12.408.6 2.264.408z" fill="#fff"/></svg>
						</span>
						<span className="TextBlock__ButtonLine">
							<span className="TextBlock__ButtonText"> {buttonText[0].text} </span>
							<svg className="TextBlock__ButtonArrow" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.264.408l-.032 1.28 8.032.16-10.24 10.24.928.896L11.16 2.744l.192 8.064 1.28-.032L12.408.6 2.264.408z" fill="#fff"/></svg>
						</span>
					</span>
				</a>
			}
		</section>
	)

}

const SelectSplitAppear = ({ children, isSplitAppear }) => {
	if (isSplitAppear) {
		return (
			<SplitText splitBy="lines">
				{children}
			</SplitText>
		)
	} else {
		return children;
	}
}

export default TextBlock;